
import { ModelConnection, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { ShareModule } from '@/modules/share/store'

import InDiagramsMenu from './in-diagrams-menu.vue'

@Component({
  components: {
    InDiagramsMenu
  },
  name: 'ModelInDiagrams'
})
export default class extends Vue {
  shareModule = getModule(ShareModule, this.$store)

  @Ref() readonly inDiagramsMenuRef!: InDiagramsMenu

  @Prop() readonly object?: ModelObject
  @Prop() readonly connection?: ModelConnection
  @Prop() readonly permission!: PermissionType
  @Prop({ default: true, type: Boolean }) readonly nextButton!: boolean

  menuOpen = false

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get diagramCount () {
    return Object.keys(this.object?.diagrams || {}).length + Object.keys(this.connection?.diagrams || {}).length
  }

  get type () {
    if (this.object) {
      return 'object'
    } else if (this.connection) {
      return 'connection'
    }
  }

  nextDiagram () {
    this.inDiagramsMenuRef?.nextDiagram()
  }
}
