
import { ModelConnection, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

export interface IFlowListItem {
  active: boolean
  click?: () => void
  id: string
  index: number
  modelId: string
  stepCount: number
  title: string
}

@Component({
  components: {
    Menu
  },
  name: 'ModelInFlowsMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly activator?: () => HTMLElement
  @Prop() readonly disabled?: boolean
  @Prop() readonly object?: ModelObject
  @Prop() readonly connection?: ModelConnection

  hoverFlowId = ''

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram?.id && o.handleId === this.currentFlowHandleId)
  }

  get flowThumbnailUrls () {
    return this.flows.reduce<Record<string, string>>((p, c) => ({
      ...p,
      [c.id]: this.flowModule.flowThumbnails[c.id]?.url || ''
    }), {})
  }

  get flows () {
    return Object
      .values(this.flowModule.flows)
      .map((flow): IFlowListItem | null => {
        const diagram = this.diagramModule.diagrams[flow.diagramId]
        if (!diagram) {
          return null
        }

        const diagramModel = this.modelModule.objects[diagram.modelId]
        if (!diagramModel) {
          return null
        }

        if (!this.object?.flows[flow.id] && !this.connection?.flows[flow.id]) {
          return null
        }

        const objectId = this.object?.diagrams[diagram.id]?.objectId
        const connectionId = this.connection?.diagrams[diagram.id]?.connectionId

        return {
          active: !!(
            flow.diagramId === this.currentDiagram?.id &&
            flow.id === this.currentFlow?.id &&
            this.$routeName?.endsWith('-diagram')
          ),
          click: async () => {
            const explore = await this.diagramModule.diagramsExplore({
              diagramId: diagram.id,
              landscapeId: this.currentLandscapeId,
              modelId: diagram.modelId,
              versionId: this.currentVersionId
            })

            if (explore) {
              this.$router.push({
                name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
                params: this.$params,
                query: this.$setQuery({
                  comment: undefined,
                  connection: connectionId,
                  diagram: explore.diagram.handleId,
                  flow: flow.handleId,
                  flow_path: undefined,
                  flow_step: undefined,
                  model: explore.modelObject.handleId,
                  object: objectId,
                  x1: undefined,
                  x2: undefined,
                  y1: undefined,
                  y2: undefined
                })
              })
            }
          },
          id: flow.id,
          index: diagram.index,
          modelId: diagram.modelId,
          stepCount: Object.values(flow.steps).filter(o => !o.type?.endsWith('-path')).length,
          title: flow.name
        }
      })
      .filter((o): o is IFlowListItem => !!o)
      .sort((a, b) => {
        if (a.modelId === b.modelId) {
          if (a.index === b.index) {
            return a.id > b.id ? -1 : 1
          } else {
            return a.index > b.index ? 1 : -1
          }
        } else {
          return a.title.localeCompare(b.title)
        }
      })
  }

  @Watch('hoverFlowId')
  onHoverDiagramIdChanged (hoverFlowId: string) {
    if (!this.flowModule.flowThumbnails[hoverFlowId]) {
      this.flowModule.flowThumbnailGet({
        flowId: hoverFlowId,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    }
  }

  opened () {
    analytics.modelInFlowsMenu.track(this, {
      landscapeId: [this.currentLandscapeId],
      modelType: this.object ? this.object.type : 'connection',
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  open () {
    if (this.flows.length <= 10) {
      this.flows
        .filter(o => !this.flowModule.flowThumbnails[o.id])
        .forEach(o => {
          this.flowModule.flowThumbnailGet({
            flowId: o.id,
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          })
        })
    }
  }
}
