
import { ModelConnection, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

export interface IDiagramListItem {
  active: boolean
  click: () => void
  id: string
  index: number
  modelId: string
  subtitle: string
  title: string
}

@Component({
  components: {
    Menu
  },
  name: 'ModelInDiagramsMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly activator?: () => HTMLElement
  @Prop() readonly disabled?: boolean
  @Prop() readonly object?: ModelObject
  @Prop() readonly connection?: ModelConnection

  hoverDiagramId = ''

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get diagramThumbnailUrls () {
    return this.diagrams.reduce<Record<string, string>>((p, c) => ({
      ...p,
      [c.id]: this.diagramModule.diagramThumbnails[c.id]?.url || ''
    }), {})
  }

  get diagrams () {
    return Object
      .values(this.diagramModule.diagrams)
      .map((diagram): IDiagramListItem | null => {
        const objectId = this.object?.diagrams[diagram.id]?.objectId
        const connectionId = this.connection?.diagrams[diagram.id]?.connectionId

        if (!objectId && !connectionId) {
          return null
        }

        const diagramModel = this.modelModule.objects[diagram.modelId]
        if (!diagramModel) {
          return null
        }

        const domain = this.domainModule.domains[diagramModel.domainId]
        if (!domain) {
          return null
        }

        return {
          active: !!(
            diagram.id === this.currentDiagram?.id &&
            this.$routeName?.endsWith('-diagram')
          ),
          click: async () => {
            const explore = await this.diagramModule.diagramsExplore({
              diagramId: diagram.id,
              landscapeId: this.currentLandscapeId,
              modelId: diagram.modelId,
              versionId: this.currentVersionId
            })

            if (explore) {
              this.$router.push({
                name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
                params: this.$params,
                query: this.$setQuery({
                  comment: undefined,
                  connection: connectionId,
                  diagram: explore.diagram.handleId,
                  flow: undefined,
                  flow_parent: undefined,
                  flow_path: undefined,
                  flow_step: undefined,
                  model: explore.modelObject.handleId,
                  object: objectId,
                  x1: undefined,
                  x2: undefined,
                  y1: undefined,
                  y2: undefined
                })
              })
            }
          },
          id: diagram.id,
          index: diagram.index,
          modelId: diagram.modelId,
          subtitle: `in: ${domain.name}`,
          title: diagram.name
        }
      })
      .filter((o): o is IDiagramListItem => !!o)
      .sort((a, b) => {
        if (a.modelId === b.modelId) {
          if (a.index === b.index) {
            return a.id > b.id ? -1 : 1
          } else {
            return a.index > b.index ? 1 : -1
          }
        } else {
          return a.title.localeCompare(b.title)
        }
      })
  }

  @Watch('hoverDiagramId')
  onHoverDiagramIdChanged (hoverDiagramId: string) {
    if (!this.diagramModule.diagramThumbnails[hoverDiagramId]) {
      this.diagramModule.diagramThumbnailGet({
        diagramId: hoverDiagramId,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    }
  }

  opened () {
    analytics.modelInDiagramsMenu.track(this, {
      landscapeId: [this.currentLandscapeId],
      modelType: this.object ? this.object.type : 'connection',
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  nextDiagram () {
    const activeIndex = this.diagrams.findIndex(o => o.active)
    const firstDiagram = this.diagrams[0]
    const nextDiagram = this.diagrams[activeIndex + 1]
    if (nextDiagram) {
      nextDiagram.click()
    } else if (firstDiagram) {
      firstDiagram.click()
    }
  }

  open () {
    if (this.diagrams.length <= 10) {
      this.diagrams
        .filter(o => !this.diagramModule.diagramThumbnails[o.id])
        .forEach(o => {
          this.diagramModule.diagramThumbnailGet({
            diagramId: o.id,
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          })
        })
    }
  }
}
