
import { ModelConnection, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { ShareModule } from '@/modules/share/store'

import InFlowsMenu from './in-flows-menu.vue'

@Component({
  components: {
    InFlowsMenu
  },
  name: 'ModelInFlows'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)

  @Prop() readonly object?: ModelObject
  @Prop() readonly connection?: ModelConnection
  @Prop() readonly permission!: PermissionType

  menuOpen = false

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get flowCount () {
    return Object.keys(this.object?.flows || {}).length + Object.keys(this.connection?.flows || {}).length
  }
}
